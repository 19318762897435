



































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {InputSupportedServerSchema} from '@/schema/resource/SupportedServer/InputSupportedServerSchema'
import {SupportedServer} from '@/model/resource/SupportedServer'

@Component
export default class PersistSupportedServerView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputSupportedServerSchema()
  supportedServer = new SupportedServer()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.supportedServer.getSupportedServer(id)
    }

    this.$await.done('getSupportedServer')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.supportedServer.persistSupportedServer()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/supported-server/list')
  }
}
