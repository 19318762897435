/**
 * Input Schema of SupportedServer
 * @author Yumi
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {SupportedServer} from '@/model/resource/SupportedServer'

export class InputSupportedServerSchema extends DefaultSchema {
  readonly name = 'InputSupportedServer'

  readonly fieldSet: FieldSet<SupportedServer> = {
    name: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 100,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 255,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    discordLink: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 50,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    serverImageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'supportedServer',
        compressDimension: $.auth.global.dappImageDimension,
        crop: true,
        class: 'scoped__image-url col-span-2',
      },
    }),
    private: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
