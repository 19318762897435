/**
 * SupportedServer
 * @author Yumi
 */
import {IResource} from '@simpli/resource-collection'
import {Request} from '@simpli/serialized-request'
import {SupportedServerCollection} from '@/model/collection/SupportedServerCollection'

export class SupportedServer implements IResource {
  idSupportedServerPk: number = 0
  name: string | null = null
  order: number | null = null
  serverImageUrl: string | null = null
  discordLink: string | null = null
  active: boolean | null = null
  private: boolean = false

  get $id() {
    return this.idSupportedServerPk
  }

  set $id(val) {
    this.idSupportedServerPk = val
  }

  get $tag() {
    return String(this.name)
  }

  /**
   * Gets an instance of a given ID of SupportedServer
   */
  async getSupportedServer(id: number) {
    return await Request.get(`/client/supported-server/${id}`)
      .name('getSupportedServer')
      .as(this)
      .getData()
  }

  /**
   * Deletes an instance of a given ID of SupportedServer
   */
  async removeSupportedServer() {
    return await Request.delete(`/client/supported-server/${this.$id}`)
      .name('removeSupportedServer')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of SupportedServer
   */
  static async listSupportedServer(params: any) {
    return await Request.get(`/client/supported-server`, {params})
      .name('listSupportedServer')
      .as(SupportedServerCollection)
      .getData()
  }

  /**
   * Persists a new instance of SupportedServer. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistSupportedServer() {
    return await Request.post(`/client/supported-server`, this)
      .name('persistSupportedServer')
      .asNumber()
      .getData()
  }

  /**
   * Increments the order of SupportedServer
   */
  async changeOrder() {
    return await Request.post(
      `/client/supported-server/${this.$id}/order`,
      this
    )
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
